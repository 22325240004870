import { CopyFilled, FileFilled, SecurityScanFilled, ShopOutlined, ShoppingFilled, UnlockFilled } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Divider, Layout, Menu, Modal, Result, Skeleton, Spin, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAccountDetails, apiAccountEdit } from '../../../../api/accounts';
import { getItemFromStorage } from '../../../../helpers/storage';
import AccessAccount from './AccessAccount/AccessAccount';
import './AccountDetails.scss';
import BusinessActivity from './BusinessActivity/BusinessActivity';
import Company from './Company/Company';
import DivisionFees from './DivisionFees/DivisionFees';
import Identifiers from './Identifiers/Identifiers';
import Responsible from './Responsible/Responsible';

const { Sider } = Layout;

// Função para verificar todos os erros nos campos obrigatórios
const hasErrors = (data) => {
  if (!data) return { person: false, company: false, businessActivity: false };

  const requiredFields = {
    person: [
      'first_name',
      'last_name',
      'email',
      'birthdate',
      'mother_name',
      'phone',
      'taxpayer_id',
      'address.neighborhood',
      'address.city',
      'address.state',
      'address.postal_code',
    ],
    company: ['name', 'email', 'tax_id'],
    businessActivity: ['type', 'statement_descriptor', 'mcc', 'email', 'revenue', 'category'],
    divisonFees: ['n1_distributor', 'n2_distributor'],
  };

  // Função auxiliar para verificar campos aninhados
  const checkNestedField = (obj, fieldPath) => {
    const keys = fieldPath.split('.');
    let value = obj;
    for (const key of keys) {
      if (value && typeof value === 'object') {
        value = value[key];
      } else {
        return undefined;
      }
    }
    return value;
  };

  // Função auxiliar para verificar se algum campo está vazio
  const hasEmptyFields = (fields, obj) => {
    return fields.some(field => {
      const value = checkNestedField(obj, field);
      return !value || String(value).trim() === '';
    });
  };

  // Verifica se há erros na atividade comercial
  const businessActivityError = hasEmptyFields(requiredFields.businessActivity, data);

  // Verifica se há erros nos campos da pessoa
  const personError = data.person && hasEmptyFields(requiredFields.person, data.person);

  // Verifica se há erros nos campos da empresa
  const companyError = data.company && hasEmptyFields(requiredFields.company, data.company);


  // Verifica se há erros nos campos da divisão de taxas
  const divisonFeesError = data.split && hasEmptyFields(requiredFields.divisonFees, data.split);
  return { person: personError, company: companyError, businessActivity: businessActivityError, divisonFees: divisonFeesError };
};


const AccountDetails = () => {
  const navigate = useNavigate();
  const user = getItemFromStorage('userInfo')
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedMenuKey, setSelectedMenuKey] = useState('1');
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const accountID = parts[parts.length - 1];
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeError, setActiveError] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nextMenuKey, setNextMenuKey] = useState(null);
  const [dataToSave, setDataToSave] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [originalAccountDetails, setOriginalAccountDetails] = useState({});

  const getChangedFields = (originalData, currentData) => {
    const changes = {};

    const setNestedValue = (obj, path, value) => {
      const keys = path.split('.');
      let current = obj;

      keys.slice(0, -1).forEach(key => {
        if (!current[key]) current[key] = {};
        current = current[key];
      });

      current[keys[keys.length - 1]] = value;
    };

    const checkChanges = (original, current, path = '') => {
      if (typeof current === 'object' && current !== null && !Array.isArray(current) &&
        typeof original === 'object' && original !== null && !Array.isArray(original)) {

        Object.keys(current).forEach(key => {
          const newPath = path ? `${path}.${key}` : key;
          if (!Object.prototype.hasOwnProperty.call(original, key)) {
            setNestedValue(changes, newPath, current[key]);
          } else if (typeof current[key] === 'object' && current[key] !== null) {
            checkChanges(original[key], current[key], newPath);
          } else if (current[key] !== original[key]) {
            setNestedValue(changes, newPath, current[key]);
          }
        });

      } else {
        if (current !== original) {
          setNestedValue(changes, path, current);
        }
      }
    };

    checkChanges(originalData, currentData);
    return changes;
  };
  const handleSaveChanges = async (editedData) => {
    const changedFields = getChangedFields(originalAccountDetails, editedData);

    if (Object.keys(changedFields).length === 0) {
      alert('Nenhuma alteração detectada.');
      return;
    }
    setLoading(true);
    const processRevenue = (revenue) => {
      return parseInt(revenue, 10) || 0;
    };

    const updateSections = {
      person: {},
      company: {},
      split: {},
      businessActivy: {}
    };

    Object.keys(changedFields).forEach(field => {
      if (field.startsWith('person.')) {
        const key = field.replace('person.', '');
        updateSections.person[key] = changedFields[field];
      } else if (field.startsWith('company.')) {
        const key = field.replace('company.', '');
        updateSections.company[key] = changedFields[field];
      } else if (field.startsWith('split.')) {
        const key = field.replace('split.', '');
        updateSections.split[key] = changedFields[field];
      } else {
        updateSections.businessActivy[field] = changedFields[field];
        if (field === 'revenue') {
          updateSections.businessActivy[field] = processRevenue(changedFields[field]);
        }
      }
    });

    try {
      if (Object.keys(updateSections.person).length > 0) {
        await apiAccountEdit(accountID, { person: updateSections.person });
      }
      if (Object.keys(updateSections.company).length > 0) {
        await apiAccountEdit(accountID, { company: updateSections.company });
      }
      if (Object.keys(updateSections.split).length > 0) {
        await apiAccountEdit(accountID, { split: updateSections.split });
      }
      if (Object.keys(updateSections.businessActivy).length > 0) {
        await apiAccountEdit(accountID, updateSections.businessActivy);
      }
      fetchAccountDetails();
      console.log("Dados salvos com sucesso!");
      setUnsavedChanges(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Erro ao salvar dados:", error);
    }
  };

  const handleDiscardChanges = async () => {
    setUnsavedChanges(false);
  };

  const handleMenuClick = (e) => {
    if (unsavedChanges) {
      setNextMenuKey(e.key);
      setIsModalVisible(true);
    } else {
      setSelectedMenuKey(e.key);
    }
  };

  const handleOk = async () => {
    setModalLoading(true);
    if (dataToSave) {
      await handleSaveChanges(dataToSave);
    }
    setSelectedMenuKey(nextMenuKey);
    setIsModalVisible(false);
    setDataToSave(null);
    setModalLoading(false);
  };

  const handleCancel = async () => {
    setModalLoading(true);
    await handleDiscardChanges();
    setSelectedMenuKey(nextMenuKey);
    setIsModalVisible(false);
    setDataToSave(null);
    setModalLoading(false);
  };

  const handleDataToSave = useCallback((data) => {
    setDataToSave(data);
  }, []);

  const fetchAccountDetails = async () => {
    try {
      const json = await apiAccountDetails(accountID);
      setAccountDetails(json);
      setOriginalAccountDetails(json);
      setLoading(false);
    } catch (error) {
      setActiveError(error?.status);
    }
  };

  useEffect(() => {
    if (accountID) {
      setLoading(true);
      fetchAccountDetails();
    }
  }, [accountID]);

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/lojistas")} type="primary">
              Lojistas
            </Button>
          }
        />
      );
    }
  };

  const renderContent = () => {
    if (loading) {
      return <Skeleton active />;
    }
    switch (selectedMenuKey) {
      case '1':
        return <BusinessActivity
          data={accountDetails}
          onSave={handleSaveChanges}
          onDiscard={handleDiscardChanges}
          setUnsavedChanges={setUnsavedChanges}
          setDataToSave={handleDataToSave}
        />;
      case '2':
        return <Responsible
          data={accountDetails}
          onSave={handleSaveChanges}
          onDiscard={handleDiscardChanges}
          setUnsavedChanges={setUnsavedChanges}
          setDataToSave={handleDataToSave} />;
      case '3':
        return <Company
          data={accountDetails}
          onSave={handleSaveChanges}
          onDiscard={handleDiscardChanges}
          setUnsavedChanges={setUnsavedChanges}
          setDataToSave={handleDataToSave} />;
      case '4':
        return <DivisionFees
          data={accountDetails}
          onSave={handleSaveChanges}
          onDiscard={handleDiscardChanges}
          setUnsavedChanges={setUnsavedChanges}
          setDataToSave={handleDataToSave} />;
      case '5':
        return <Identifiers data={accountDetails} />;
      case '6':
        return <AccessAccount dataInfo={accountDetails} />;
      default:
        return <div>Selecione uma opção do menu</div>;
    }
  };

  const getMenuWithErrors = () => {
    const errors = hasErrors(accountDetails);

    return menuItems.map(item => {
      const hasError = (
        (item.key === '1' && errors.businessActivity) ||
        (item.key === '2' && errors.person) ||
        (item.key === '3' && errors.company) ||
        (item.key === '4' && errors.divisonFees)
      );
      return {
        ...item,
        label: (
          <Badge
            count={hasError ? '!' : 0}
            className='error-badge'
            style={{ backgroundColor: '#f5222d', color: '#fff' }}
          >
            {item.label}
          </Badge>
        )
      };
    });
  };

  const menuItems = [
    { key: '1', label: 'Atividade comercial', icon: React.createElement(FileFilled) },
    { key: '2', label: 'Responsável', icon: React.createElement(SecurityScanFilled) },
    accountDetails?.type === "SELLER_PJ" && { key: '3', label: 'Empresa', icon: React.createElement(ShopOutlined) },
    (accountDetails?.type === "SELLER_PJ" || accountDetails?.type === "SELLER_PF") && { key: '4', label: 'Divisão de Taxas', icon: React.createElement(ShoppingFilled) },
    { key: '5', label: 'Identificadores', icon: React.createElement(CopyFilled) },

    JSON.parse(user)?.is_superuser && { key: '6', label: 'Acessos', icon: React.createElement(UnlockFilled) }
  ].filter(Boolean);

  return (
    <>
      {activeError && renderError(activeError)}
      {!activeError && (
        <div className='AccountDetails'>
          <Layout
            style={{
              width: "100%",
              padding: "1rem 20px",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                {loading ? <Skeleton title={false} paragraph={{ rows: 1 }} active /> : <h2 className="title-page">{accountDetails?.display_name}</h2>}
              </div>
              <Divider />
              <Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Cadastro do cliente</Breadcrumb.Item>
              <Breadcrumb.Item>{loading ? <Skeleton title={false} paragraph={{ rows: 1 }} active /> : `${accountDetails?.person?.first_name || accountDetails?.display_name} ${accountDetails?.person?.last_name || ""}`}</Breadcrumb.Item>
            </Breadcrumb>
          </Layout>
          <div className='content-container'>
            <Sider
              collapsedWidth="0"
              className='sider-container'
              style={{
                boxSizing: 'border-box',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {loading ? <Skeleton style={{ padding: 10 }} title={false} paragraph={{ rows: 3 }} active /> :
                <Menu
                  mode="inline"
                  selectedKeys={[selectedMenuKey]}
                  onClick={handleMenuClick}
                  items={getMenuWithErrors()}
                />
              }
            </Sider>
            <Content
              style={{
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {renderContent()}
            </Content>
          </div>
        </div>
      )}
      <Modal
        title="Confirmar"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={modalLoading ? null : [
          <Button key="back" onClick={handleCancel}>
            Não
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Sim
          </Button>,
        ]}
      >
        {modalLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <p>Você tem alterações não salvas. Deseja salvar?</p>
        )}
      </Modal>
    </>
  );
};

export default AccountDetails;
