import { Divider, Form, Input } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import './Identifiers.scss';

const Identifiers = ({ data }) => {
  return (
    <div className='Identifiers'>
      <p className='subtitle-page'>Identificadores</p>
      <Divider />
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item label="Criado em">
              <Input disabled value={format(data?.created_at, "dd/MM/yyyy 'às' HH:mm:ss") || "Não informado"} readOnly />
            </Form.Item>
            <Form.Item label="Identificador unificado">
              <Input disabled value={data?.id || "Não informado"} readOnly />
            </Form.Item>
          </Form>
        </div>

        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item label="Última atualização">
              <Input disabled value={format(data?.updated_at, "dd/MM/yyyy 'às' HH:mm:ss") || "Não informado"} readOnly />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Divider />
      <p className='subtitle-page'>Seção Zoop</p>
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item label="Identificador ">
              <Input disabled value={data?.zoop_id || "Não informado"} readOnly />
            </Form.Item>
            <Form.Item label="Última atualização em">
              <Input disabled value={data?.zoop_last_updated ? format(data?.zoop_last_updated, "dd/MM/yyyy 'às' HH:mm:ss") || "Não informado" : "Não informado"} readOnly />
            </Form.Item>
          </Form>
        </div>

        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item label="Status ">
              <Input disabled value={data?.zoop_acquirer_status || "Não informado"} readOnly />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Identifiers
