import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Result,
  Select,
  Skeleton,
  Spin,
  Table,
  message,
  notification,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiAccessAccounts, apiAccessDelete, apiAccessDetails, apiAccessEdit, apiCreateAccessAccount, apiDeleteAccessAccount } from "../../../../api/access";
import { apiAccountsList } from "../../../../api/accounts";
import { formatDate } from "../../../../utils/format";
import { useIsMobileHook } from "../../../../utils/WindowHooks";
import "./AccessInfo.scss";
const AccessInfo = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const accessID = parts[parts.length - 1];
  const [name, setName] = useState();
  const [email, setEmail] = useState("TESTE");
  const [date, setDate] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [role, setRole] = useState(false);
  const [userID, setUserID] = useState();
  const [loadingFetch, setLoadingFetch] = useState(false);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = async () => {
    setLoadingFetch(true)
    try {
      const jsonEdit = {
        username: name,
        is_superuser: role === 'admin' ? true : false ? role ? true : true : false,
        email,
      }

      if (password) {
        jsonEdit.password = password;
      }

      await apiAccessEdit(userID, jsonEdit)
      setLoadingFetch(false)
      notification.success({
        message: "Sucesso",
        description: "Acesso editado com sucesso.",
        placement: "topRight",
      });
      navigate(`/admin/acessos`);
    } catch (error) {
      setLoadingFetch(false)
      if (error.status === 400 && error?.message?.username?.[0] === "Um usuário com este nome de usuário já existe.") {
        form.setFields([
          {
            name: "name",
            errors: [
              "Um usuário com este nome de usuário já existe. Por favor, escolha outro usuário.",
            ],
          },
        ]);
        notification.warning({
          message: "Atenção!",
          description:
            "Um usuário com este nome de usuário já existe. Por favor, escolha outro usuário.",
          placement: "topRight",
        });
      } else if (error.status === 400 && error?.message?.[0] === "Email already exists") {
        form.setFields([
          {
            name: "email",
            errors: [
              "O email inserido já está em uso. Por favor, escolha outro email.",
            ],
          },
        ]);
        notification.warning({
          message: "Atenção!",
          description:
            "O email inserido já está em uso. Por favor, escolha outro email.",
          placement: "topRight",
        });
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao editar o acesso. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao processar a requisição. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      }
    }
  };
  const initialValues = {
    name: "",
    email: "",
    date_joined: "",
    role: "",
  };


  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const validateUsername = (_, value) => {
    const regex = /^[a-zA-Z0-9@\/.+_-]*$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        "O nome de usuário deve conter apenas letras, números e os caracteres @/./+/-/_"
      )
    );
  };

  const handleList = async () => {
    setLoading(true);
    try {
      await apiAccessDetails(accessID).then((json) => {
        form.setFieldsValue({ name: json?.username });
        setName(json.username);
        form.setFieldsValue({ email: json?.email });
        setEmail(json.email);
        form.setFieldsValue({ date_joined: json?.date_joined });
        setDate(json.date_joined);
        form.setFieldsValue({
          role: json?.is_superuser === false ? "vendedor" : "admin",
        });
        setRole(json?.is_superuser === false ? "vendedor" : "admin");
        setUserID(json?.id)
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar as informações!",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar as informações. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      }
    }
  };

  useEffect(() => {
    handleList();
  }, []);

  const deleteCheck = async () => {
    try {
      const deleteResponse = await apiAccessDelete(accessID);
      if (deleteResponse.status === 204) {
        return { deleteResponse };
      } else {
        throw new Error("Erro ao excluir checkout ou plano de pagamento");
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteContent = async () => {
    try {
      await deleteCheck();
      notification.info({
        message: "Sucesso",
        description: "Acesso foi excluído com sucesso!",
        placement: "topRight",
      });
      navigate(`/admin/acessos`);
    } catch (error) {
      notification.error({
        message: "Erro",
        description:
          "Ocorreu um erro ao excluir o acesso. Por favor, tente novamente mais tarde.",
        placement: "topRight",
      });
    }
  };

  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.name && values.email) {
          handleSubmit();
        } else {
          message.error("Preencha todos os campos obrigatórios.");
        }
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  const validateEmail = (rule, value, callback) => {
    if (value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        callback('Por favor, insira um email válido!');
      } else {
        callback();
      }
    } else {
      callback('Por favor, insira o email');
    }
  };

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const isMobile = useIsMobileHook(1400);

  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };


  const getAccesses = async () => {
    setLoading(true);
    try {
      const json = await apiAccessAccounts(userID);
      setData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar os acessos.",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar os acessos. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
        notification.error("Erro ao buscar os acessos.");
      }
    }
  };

  useEffect(() => {
    if (userID) {
      getAccesses();
    }
  }, [userID]);

  const confirmDelete = (record) => {
    Modal.confirm({
      title: 'Tem certeza que deseja deletar?',
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Deletar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => handleDelete(record?.id, userID),
    });
  };

  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  const handleCancel = () => {
    setIsSecondModalVisible(true)
  };

  const fetchOptions = async (search) => {
    setModalLoading(true);
    try {
      const json = await apiAccountsList({ search });
      setOptions(json.results.map(item => ({ label: item.email, value: item.id })));
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    if (searchText !== '') {
      fetchOptions(searchText);
    } else {
      setOptions([]);
    }
  }, [searchText]);

  const handleSecondModalCancel = () => {
    setIsSecondModalVisible(false);
  };

  const handleCreateAccount = async () => {
    try {
      await apiCreateAccessAccount(userID, options[0].value).then(json => {
        notification.success({
          message: "Acesso criado com sucesso",
          description: "Acesso criado com sucesso",
          placement: "topRight",
        });
        setIsSecondModalVisible(false)
        getAccesses();
      })
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao criar o acesso.",
        placement: "topRight",
      });
    }
  };

  const handleDelete = async (id, idUser) => {
    try {
      await apiDeleteAccessAccount(idUser, id).then(json => {
        notification.success({
          message: "Acesso deletado com sucesso",
          description: "Acesso deletado com sucesso",
          placement: "topRight",
        });
        setIsSecondModalVisible(false)
        getAccesses();
      })
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao deletar o acesso.",
        placement: "topRight",
      });
    }
  };


  const columns = [
    {
      title: "Nome",
      dataIndex: ["seller", "display_name"],
      key: "user_name",
      render: (username) => (
        <p dangerouslySetInnerHTML={{ __html: username }} />
      ),
    },
    {
      title: "Data de Entrada",
      dataIndex: "created_at",
      key: "date_joined",
      render: (date_joined) => (
        <p dangerouslySetInnerHTML={{ __html: new Date(date_joined).toLocaleDateString() }} />
      ),
      responsive: ["xl"],
    },
    {
      title: "Cargo",
      dataIndex: "is_superuser",
      key: "is_superuser",
      render: (is_superuser) => (
        <p style={{ textTransform: "capitalize" }} className={`badge ${is_superuser ? "badge-success" : "badge-error"}`}>
          {is_superuser ? "Administrador" : "Vendedor"}
        </p>
      ),
      responsive: ["sm"],
    },
    {
      title: "Detalhes",
      dataIndex: "",
      render: (record) => (
        <Link to={`/lojista/detalhes/${record?.seller?.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      ),
      key: "info",
      responsive: ["sm"],
    },
    {
      title: "Excluir",
      dataIndex: "",
      render: (record) => (
        <DeleteOutlined onClick={() => confirmDelete(record)} style={{ cursor: "pointer", height: "100%" }} />
      ),
      key: "info",
      responsive: ["sm"],
    },
  ];


  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
        Nome:{" "}
        <p dangerouslySetInnerHTML={{ __html: record.seller ? record.seller.display_name : record.account.display_name }} />
      </span>
      <span>
        Cargo:{" "}
        <p style={{ textTransform: "capitalize" }} className={`badge ${record.is_superuser ? "badge-success" : "badge-error"}`}>
          {record.is_superuser ? "Administrador" : "Vendedor"}
        </p>
      </span>
      <span>
        Entrada no sistema:{" "}
        <p dangerouslySetInnerHTML={{ __html: record?.created_at ? formatDate(record?.created_at) : "" }} />
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link to={`/lojista/detalhes/${record.seller ? record?.seller?.id : record?.account?.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
      <span style={{ marginTop: "10px" }}>
        Deletar:{" "}
        <DeleteOutlined onClick={() => confirmDelete(record)} style={{ cursor: "pointer", height: "100%" }} />
      </span>
    </div>
  );

  return (
    <div className="AccessInfo">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <Breadcrumb
                  style={{
                    margin: "16px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <h2 className="title-page">{name}</h2>
                    <Button
                      onClick={() => setEnableEdit(!enableEdit)}
                      type={`${enableEdit ? "primary" : ""}`}
                    >
                      EDITAR
                    </Button>
                  </div>
                  <Divider />
                  <Breadcrumb.Item>Detalhe</Breadcrumb.Item>
                  <Breadcrumb.Item>{name + " - " + email}</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">Informações de Acesso</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <Form form={form} initialValues={initialValues}>
                        <Form.Item
                          label="Nome"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o nome",
                            },
                            {
                              validator: validateUsername,
                            },
                          ]}
                        >
                          <Input
                            disabled={!enableEdit}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o email",
                            },
                            {
                              validator: validateEmail,
                            },
                          ]}
                        >
                          <Input
                            disabled={!enableEdit}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Cargo"
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, selecione o cargo",
                            },
                          ]}
                        >
                          <Radio.Group
                            disabled={!enableEdit}
                            onChange={handleRoleChange}
                            value={role ? "admin" : "vendedor"}
                          >
                            <Radio value="admin">Administrador</Radio>
                            <Radio value="vendedor">Vendedor</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="container-right">
                      <Form form={form}>
                        <Form.Item
                          label="Data de entrada no sistema"
                          name="date_joined"
                          rules={[
                            {
                              required: false,
                              message:
                                "Por favor, selecione a data de vencimento.",
                            },
                          ]}
                        >
                          <p className="info-date">
                            {date && formatDate(date, "dd/MM/yyyy")}
                          </p>
                        </Form.Item>

                        <Form.Item
                          label="Nova senha (Opcional)"
                          name="password"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="****************"
                            type="password"
                            disabled={!enableEdit}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">Acessos</h3>
                    <Button onClick={handleCancel} type="primary">
                      Adicionar acesso a vendedor
                    </Button>
                  </div>
                  <Divider />
                  {loading ? (
                    <Skeleton active />
                  ) : (
                    <Table
                      columns={columns}
                      locale={{ emptyText: <Empty description="Sem dados" /> }}
                      dataSource={data.map((item) => {
                        if (!item.seller) {
                          item.seller = item.account;
                        }
                        return { ...item, key: item.id }
                      })}
                      expandable={{
                        expandedRowRender: isMobile ? expandedRowRender : undefined,
                        expandedRowKeys: expandedRowKeys,
                        onExpand: (expanded, record) => handleRowClick(record),
                        responsive: ["xl"],
                      }}
                      pagination={{
                        current: pagination.current,
                        total: pagination.total,
                        showSizeChanger: false,
                      }}
                      bordered
                    />
                  )}
                </Content>

                <div
                  className="container-buttons"
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyItems: "space-between",
                    width: "100%",
                  }}
                >
                  <Button onClick={() => deleteContent()} type="primary" danger>
                    Excluir acesso
                  </Button>
                  {enableEdit && (
                    <Button loading={loadingFetch} onClick={() => onFinish()} type="primary">
                      Salvar alterações
                    </Button>
                  )}
                </div>
              </>
            )}
          </Layout>
          <Modal
            title="Confirmação"
            visible={isSecondModalVisible}
            onCancel={handleSecondModalCancel}
            footer={null}
          >
            <div style={{ marginBottom: '16px' }}>
              <Select
                showSearch
                placeholder="Selecione um usuário"
                notFoundContent={modalLoading ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={value => setSearchText(value)}
                style={{ width: '100%' }}
              >
                {options.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <Button
              type="primary"
              onClick={handleCreateAccount}
              style={{ marginRight: '8px' }}
            >
              Confirmar
            </Button>
            <Button
              type="default"
              onClick={handleSecondModalCancel}
            >
              Cancelar
            </Button>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AccessInfo;
