import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSellerID } from "../../../api/common";
import { getAccess, login } from "../../../api/login";
import logo from "../../../assets/images/logo.png";
import SellerDropdown from "../../common/SellerDropdown/SellerDropdown";
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await login(values.email, values.password);
      setLoading(false);
      const access = await getAccess(data?.user?.id);
      if (access.count === 1) {
        const selected = access.results[0];
        const sellerData = { id: selected.seller.id, display_name: selected.seller.display_name };
        localStorage.setItem("seller", JSON.stringify(sellerData));
        navigate("/");
      }
      getSellerID() ? navigate("/") : setModalVisible(true);
    } catch (error) {
      console.log(error.message.detail);
      setLoading(false);

      if (
        error.status === 401 &&
        error.message.detail === "No active account found with the given credentials"
      ) {
        setLoginError("Essa conta ainda não possui acesso a vendedores.");
      } else {
        setLoginError("Falha ao fazer login. Verifique suas credenciais.");
      }
    }
  };

  const handleAlertClose = () => {
    setLoginError(null);
  };

  return (
    <div className={"Login"}>
      <div className={"form-container"}>
        <div className={"logo"}>
          <img src={logo} alt="Logo" />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Por favor, insira seu usuário!" },
              { type: "text", message: "usuário inválido!" },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Por favor, insira sua senha!" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Senha"
              size="large"
            />
          </Form.Item>
          {loginError && (
            <Alert
              message={loginError}
              type="error"
              closable
              onClose={handleAlertClose}
              style={{ marginBottom: 16 }}
            />
          )}
          <Form.Item>
            <div className="container-buttons">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                size="large"
              >
                Entrar
              </Button>
              <Button
                type="link"
                onClick={() => navigate("/reset-password")}
                size="small"
              >
                Esqueceu sua senha?
              </Button>
            </div>
          </Form.Item>
        </Form>
        <div className={"register"}>
          <Button
            type="text"
            onClick={() => navigate("/register")}
            size="small"
          >
            Não possui uma conta?
          </Button>
          <Button onClick={() => navigate("/register")} size="small">
            Quero me cadastrar
          </Button>
        </div>
      </div>
      <div className={"background"}>
        <span>O melhor meio de pagamento feito para você.</span>
      </div>
      <Modal
        title="Selecionar Vendedor"
        open={modalVisible}
        centered
        footer={null}
      >
        <SellerDropdown initial />
      </Modal>
    </div>
  );
};

export default Login;
